import { Button } from "primereact/button"
import { Card } from "primereact/card"
import { Dropdown } from "primereact/dropdown"

const ControlPanel = ({ t, setExerciseDialogVisible, tabIndex, setTabIndex, planTimeLine, setPlanTimeLine, paitentHistoryDate, setPaitentHistoryDate, painDateOptions }: any) => {

    const planTimeLineOptions = [t("completed"), t("ongoing"), t("upcoming")]

    return (
        <>
            <Card style={{ borderRadius: "12px", position: "relative" }} className="controlPanel">
                <div className="flex justify-content-between align-items-center">
                    <div >
                        <Button
                            text
                            onClick={() => setTabIndex(0)}
                            style={{
                                backgroundColor: "white",
                                color: tabIndex === 0 ? "#E3506B" : "#2D3967",
                            }} >
                            <span className="flex justify-content-center align-items-center gap-2 font-bold" style={{ width: "100%" }}>
                                {tabIndex === 0 ? <img src="/plansAndProgressRedIcon.svg" /> : <img src="/plansAndProgressIcon.svg" />}
                                {t("plansAndProgress")}
                            </span>
                        </Button>
                        <Button
                            text
                            onClick={() => setTabIndex(1)}
                            style={{
                                backgroundColor: "white",
                                color: tabIndex === 1 ? "#E3506B" : "#2D3967",
                            }} >
                            <span className="flex justify-content-center align-items-center gap-2 font-bold" style={{ width: "100%" }}>
                                {tabIndex === 1 ? <img src="/painInformationRedIcon.svg" /> : <img src="/painInformationIcon.svg" />}
                                {t("patientHistory")}
                            </span>
                        </Button>
                    </div>
                    <div className="flex flex-wrap gap-2">

                        {tabIndex === 0 && <>
                            <Button
                                onClick={() => { setExerciseDialogVisible(true) }}
                                style={{
                                    backgroundColor: "#E3506B",
                                    color: "white",
                                    border: "#E3506B",
                                    height: "3rem",
                                    borderRadius: "10px",
                                    width: "10rem",
                                    fontWeight: "bold"
                                }}
                            >
                                <span className="flex justify-content-center align-items-center gap-2 font-bold" style={{ width: "100%" }}>
                                    <img src="/addPatientIcon.svg" />
                                    {t("addPlan")}
                                </span>
                            </Button>

                            <Dropdown style={{ height: '3rem', width: '10rem' }} value={planTimeLine} options={planTimeLineOptions}
                                onChange={e => setPlanTimeLine(e.target.value)} />
                        </>}

                        {tabIndex === 1 && <>
                            <Dropdown style={{ height: '3rem', width: '10rem' }} value={paitentHistoryDate} onChange={(e) => setPaitentHistoryDate(e.value)} options={painDateOptions} optionLabel="name" />
                        </>}
                    </div>
                </div>

            </Card>

        </>
    )
}

export default ControlPanel