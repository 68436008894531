import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { Image } from 'primereact/image';
import { InputText } from 'primereact/inputtext';
import { FloatLabel } from 'primereact/floatlabel';
import { Divider } from 'primereact/divider';
import { Password } from 'primereact/password';
import { TokenResponse, useGoogleLogin } from '@react-oauth/google';
import AppleSignin from 'react-apple-signin-auth';
import { useConsultantLogin, useLoginWithApple, useLoginWithGoogle } from '../hooks/useGetDataQuery';
import { AppleLoginRequest, AppleResponse, GoogleLoginRequest } from '../Models/loginModels';
import { useToastContext } from '../Lib/Contexts/toastContext';
import { Dropdown } from 'primereact/dropdown';
import { useTranslation } from 'react-i18next';
import { locale } from 'primereact/api';
import { Checkbox } from 'primereact/checkbox';

const Login = () => {
    const { showToast } = useToastContext();
    const { t, i18n } = useTranslation();
    const [buttonClickForToast, setButtonClickForToast] = useState<any>(false)
    const [lang, setLang] = useState<any>()
    const [remember, setRemember] = useState<any>()

    const countries = [
        { name: 'EN', code: 'GB', lang: "en" },
        { name: 'TR', code: 'TR', lang: 'tr' },
    ];

    const {
        mutate: consultantLoginMutation,
        data: consultantLoginData,
        isError: isErrorConsultantLogin,
        isSuccess: isSuccessConsultantLogin,
        error: consultantLoginError,
    } = useConsultantLogin();

    const {
        mutate: loginWithAppleMutation,
        data: loginWithAppleData,
        isError: isErrorLoginWithApple,
        isSuccess: isSuccessLoginWithApple,
        error: loginWithAppleError,
    } = useLoginWithApple();

    const {
        mutate: loginWithGoogleMutation,
        data: loginWithGoogleData,
        isError: isErrorLoginWithGoogle,
        isSuccess: isSuccessLoginWithGoogle,
        error: loginWithGoogleError,
    } = useLoginWithGoogle();

    const [formData, setFormData] = useState({
        email: '',
        password: ''
    })

    useEffect(() => {
        localStorage.getItem("i18nextLng") === "en" ? setLang({ name: 'EN', code: 'GB', lang: "en" })
            : setLang({ name: 'TR', code: 'TR', lang: 'tr' })
    }, []);

    useEffect(() => {
        if (buttonClickForToast) {
            showToast({ severity: 'success', summary: 'Success', detail: 'You have successfully logged in!' });
            setButtonClickForToast(false)
            validateLogin();
        }
    }, [isSuccessLoginWithApple]);

    useEffect(() => {
        if (buttonClickForToast) {
            showToast({ severity: 'error', summary: 'Error', detail: (loginWithAppleError as any)?.response.data.message });
            setButtonClickForToast(false)
        }
    }, [isErrorLoginWithApple]);

    useEffect(() => {
        if (buttonClickForToast) {
            showToast({ severity: 'success', summary: 'Success', detail: 'You have successfully logged in!' });
            setButtonClickForToast(false)
            validateLogin();
        }
    }, [isSuccessLoginWithGoogle]);


    useEffect(() => {
        if (buttonClickForToast) {
            debugger
            showToast({ severity: 'error', summary: 'Error', detail: (loginWithGoogleError as any)?.response.data.message });
            setButtonClickForToast(false)
        }
    }, [isErrorLoginWithGoogle]);

    useEffect(() => {
        if (buttonClickForToast) {
            showToast({ severity: 'success', summary: 'Success', detail: 'You have successfully logged in!' });
            setButtonClickForToast(false)
            validateLogin();
        }
    }, [isSuccessConsultantLogin]);

    useEffect(() => {
        if (buttonClickForToast) {
            showToast({ severity: 'error', summary: 'Error', detail: (consultantLoginError as Error)?.message });
            setButtonClickForToast(false)
        }

    }, [isErrorConsultantLogin]);

    useEffect(() => {
        if (lang) {
            i18n.changeLanguage(lang.lang)
            locale(lang.lang);
        }
    }, [lang]);

    const navigate = useNavigate();

    const validateLogin = () => {
        navigate({ pathname: "/mobithera" });
    }

    const handleContinueWithGoogle = useGoogleLogin({
        onSuccess: (tokenResponse: Omit<TokenResponse, "error" | "error_description" | "error_uri">) => {
            const requestBody: GoogleLoginRequest = {
                credentials: {
                    accessToken: tokenResponse.access_token
                },
                securityKey: "p#Tb4o34bgm7Lxe7y9M%JLQZWAR^L9QhRiNwRVUwHU9JvQmWB$y9p8i25HDbNRo$ofF7QbApB7AT@T22QqKiwV!tUBHqYd%4wkqWCfMxURVPJnpKv#Ur&G@o^WqR!n^k"
            }
            loginWithGoogleMutation(requestBody);
            setButtonClickForToast(true);
        },
    });

    const handleContinueWithApple = (response: AppleResponse) => {
        setButtonClickForToast(true);
        const requestBody: AppleLoginRequest = {
            credentials: {
                firstName: response?.user?.name.firstName,
                lastName: response?.user?.name.lastName,
                email: response?.user?.email,
                idToken: response?.authorization?.id_token
            },
            securityKey: "p#Tb4o34bgm7Lxe7y9M%JLQZWAR^L9QhRiNwRVUwHU9JvQmWB$y9p8i25HDbNRo$ofF7QbApB7AT@T22QqKiwV!tUBHqYd%4wkqWCfMxURVPJnpKv#Ur&G@o^WqR!n^k"
        }
        loginWithAppleMutation(requestBody);
    }

    const handleAPILogin = (response: any) => {
        consultantLoginMutation(response);
        setButtonClickForToast(true);
    }

    const handleEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;

        // validate email

        setFormData({
            ...formData,
            email: value
        })
    }

    const handlePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;

        // validate

        setFormData({
            ...formData,
            password: value
        })
    }

    const passwordArea = {
        passwordFooter: (
            <React.Fragment>
                <Divider />
                <p className='mt-2'>{t('suggestions')}</p>
                <ul className='pl-2 ml-2 mt-0' style={{ lineHeight: '1.5' }}>
                    <li>{t('lowerCase')}</li>
                    <li>{t('upperCase')}</li>
                    <li>{t('numeric')}</li>
                    <li>{t('minimum8')}</li>
                </ul>
            </React.Fragment>
        )
    }

    const countryOptionTemplate = (option: any) => {
        return (
            <div className="flex align-items-center">
                <img alt={option.name} src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`} className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px' }} />
                <div>{option.name}</div>
            </div>
        );
    };

    const selectedCountryTemplate = (option: any, props: any) => {
        if (option) {
            return (
                <div className="flex align-items-center">
                    <img alt={option.name} src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                        className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px' }} />
                    <div>{option.name}</div>
                </div>
            );
        }

        return <span>{props.placeholder}</span>;
    };

    const dontHaveAccount = () => <span className='font-medium' style={{color:"#2D3967"}}>{t('noAcc')}? <a href='#' style={{color:"#2D3967"}} className='font-semibold'>{t('signUp')}</a></span>

    const appleRedirectURIHost = window.location.origin;

    return (
        <div style={{ backgroundColor: "#F5F5F5" }} className='w-full h-full '>
            <div className='flex flex-row'>
                <div className='w-10 overflow-hidden' style={{ height: "100vh" }}>
                    <img src="/signin-image.jpg" alt='Signing Image' width='100%' height='100%' style={{ objectFit: "cover" }} />
                </div>
                    <div className='w-full flex flex-column justify-content-center align-items-center px-4' >
                        <div className='flex flex-column  align-items-start'>
                            <div className='flex w-full justify-content-end'>
                                <Dropdown value={lang} onChange={(e) => setLang(e.value)} options={countries} optionLabel="name"
                                    valueTemplate={selectedCountryTemplate} itemTemplate={countryOptionTemplate}
                                    className='w-5rem' style={{ border: '1.5px solid #2D3967',backgroundColor:"#F5F5F5" }} />
                            </div>
                            <p className='font-bold text-6xl m-0'><span style={{ color: "#2D3967" }}>{t("welcomeTo")}</span> <span style={{ color: "#E3506B" }}>MobiThera</span></p>
                            <p className='text-4xl m-0' style={{ color: "#727A99" }}>{t('signInAccount')}</p>

                            <form onSubmit={validateLogin} className='p-fluid w-full mt-6'>
                                <div className='flex flex-column gap-5 align-items-center mb-2'>
                                    <div className='w-full'>
                                        <FloatLabel>
                                            <InputText className='p-inputtext-lg w-full' variant="filled" id='email' value={formData.email} onChange={(e) => handleEmail(e)} />
                                            <label htmlFor='email'>{t('usenameOrEmal')}</label>
                                        </FloatLabel>
                                    </div>
                                    <div className='w-full'>
                                        <FloatLabel>
                                            <Password id="password" className='p-inputtext-lg w-full' toggleMask footer={passwordArea.passwordFooter} variant='filled' value={formData.password} onChange={(e) => handlePassword(e)} />
                                            <label htmlFor="password">{t('password')}</label>
                                        </FloatLabel>
                                    </div>
                                </div>
                                <div className='flex justify-content-between  mt-4'>
                                    <div className="flex align-items-center">
                                        <Checkbox name="pizza" value="Cheese" onChange={e => setRemember(e.checked)} checked={remember} />
                                        <label className="ml-2" style={{ color: "#2D3967" }}>{t('rememberMe')}</label>
                                    </div>
                                    <a href='#' style={{ color: "#64ADF0" }}>{t('forgotPass')}</a>
                                </div>
                                <Button disabled style={{ backgroundColor: "#E3506B", borderRadius: "10px", border: "none" }} className='w-full mt-4' size="large" label={t('signIn')} severity='secondary' type='submit' />
                            </form>


                            <div className='flex w-full justify-content-center align-items-center mb-2 mt-2'>
                                <Divider />
                                <span className='p-3' style={{ whiteSpace: "nowrap", color: "#727A99" }}> <span className='font-bold' style={{ color: "#2D3967" }}>{t("signInWithOther")}</span> {t("othersignIn")}</span>
                                <Divider />
                            </div>

                            <div className='flex w-full flex-column gap-3 align-items-center mb-6'>
                                <Button onClick={() => handleContinueWithGoogle()} className='w-full flex justify-content-center' style={{ backgroundColor: '#fff', border: '1.5px solid #2D3967', borderRadius: "10px" }} size="large">
                                    <div className='flex gap-2 align-items-center'>
                                        <img alt="google-logo" src="/social-media-google.png"></img>
                                        <span className='font-semibold' style={{color:"#2D3967"}}>{t('google')}</span>
                                    </div>
                                </Button>
                                <AppleSignin
                                    authOptions={{
                                        clientId: 'com.tiga.mobicareapp.web',
                                        /* eslint-disable no-restricted-globals */
                                        redirectURI: `${appleRedirectURIHost}/callback`,
                                        scope: 'name email',
                                        usePopup: true,
                                    }}
                                    uiType="dark"
                                    className="apple-auth-btn"
                                    noDefaultStyle={false}
                                    buttonExtraChildren="Continue with Apple"
                                    onSuccess={(response: any) => handleContinueWithApple(response)}
                                    onError={() => { }}
                                    skipScript={false}
                                    render={(props: any) => (
                                        <Button {...props} className='w-full flex justify-content-center' style={{ backgroundColor: '#2D3967',border:"none",borderRadius:"10px" }} size="large">
                                            <div className='flex gap-2 align-items-center'>
                                                <img alt="google-logo" src="/social-media-apple.png"></img>
                                                <span className='font-semibold' style={{color:"white"}}>{t('apple')}</span>
                                            </div>
                                        </Button>
                                    )}
                                />
                            </div>

                            <div className='w-full flex justify-content-center'>
                                {dontHaveAccount()}
                            </div>

                        </div>
                    </div>
  
            </div>
        </div>
    );
}

export default Login