import React, { useState } from 'react';
import { Card } from 'primereact/card';
import { Carousel } from 'primereact/carousel';
import { Button } from 'primereact/button';


const LibraryCarusel = ({ caruselExercise, setExerciseVideoDialogVisible, setSelectedExercise, t }: any) => {
    const lang = localStorage.getItem("i18nextLng") === "en" ? "en-US" : "tr-TR";
    const [hoveredExerciseId, setHoveredExerciseId] = useState(null);


    const responsiveOptions = [
        {
            breakpoint: '1440px',
            numVisible: 4,
            numScroll: 1
        },
        {
            breakpoint: '1024px',
            numVisible: 3,
            numScroll: 1
        },
        {
            breakpoint: '768px',
            numVisible: 2,
            numScroll: 1
        },
        {
            breakpoint: '576px',
            numVisible: 1,
            numScroll: 1
        }
    ];

    const caruselTemplate = (exercise: any) => {
        const isHovered = hoveredExerciseId === exercise.id;
        const circleImageSrc = isHovered ? "/exerciseCircleIconOnHover.png" : exercise.circleImage;

        return (
            <div
                className="flex m-2 pl-4 h-15rem justify-content-between gap-2 clickableCarouselDiv"
                style={{
                    backgroundColor: "#FFFFFF",
                    borderRadius: "18px",
                    boxShadow: "0 4px 4px -1px rgba(45, 57, 103, 0.05), 0 4px 10px -1px rgba(45, 57, 103, 0.1)",
                    position: "relative"
                }}
                onMouseEnter={() => setHoveredExerciseId(exercise.id)}
                onMouseLeave={() => setHoveredExerciseId(null)}
                onClick={() => { setExerciseVideoDialogVisible(true); setSelectedExercise(exercise) }}
            >
                <div className="flex flex-column justify-content-between gap-2 pt-4 pb-4">
                    <span><img src="/exerciseIcon.svg" alt="Exercise Icon" /></span>
                    <p className="text-lg font-bold mb-0" style={{ color: "#2D3967" }}>{exercise.name}</p>
                </div>

                <img src={exercise.image} style={{ objectFit: "contain", width: "55%", zIndex: 2 }} />

                <img
                    src={circleImageSrc}
                    style={{
                        objectFit: "contain",
                        width: "50%",
                        position: "absolute",
                        top: "50%",
                        left: "70%",
                        transform: "translate(-50%, -50%)",
                        zIndex: 1
                    }}
                />

                <Button
                    icon={<img src="/playIcon.svg" />}
                    style={{
                        border: "none",
                        backgroundColor: "rgba(227, 80, 107, 0.9)",
                        borderRadius: "30px",
                        objectFit: "contain",
                        width: "3.5rem",
                        height: "3.5rem",
                        position: "absolute",
                        top: "50%",
                        left: "70%",
                        transform: "translate(-50%, -50%)",
                        zIndex: 3
                    }} />

            </div>
        )
    }
    return (
        <Card style={{ borderRadius: "24px", position: "relative", backgroundColor: "#F5F5F5", boxShadow: "none" }}>
            <p className='text-xl mx-2' style={{ color: "#727A99" }}>{t("exerciseLibrary")}</p>
            <Carousel value={caruselExercise} numVisible={5} numScroll={1} itemTemplate={caruselTemplate} responsiveOptions={responsiveOptions} />
        </Card>

    )
}

export default LibraryCarusel