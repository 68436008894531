import React, { useEffect, useState } from 'react';
import { Card } from 'primereact/card';
import Top4Card from './top4Card';
import Bottom2Card from './bottom2Card';
import { useOutletContext } from 'react-router-dom';
import PatientTable from '../Utils/Tables/patientTable';
import "./home.css";
import { useGetPlanListCount, useGetPlanListCountQuery } from '../hooks/useGetDataQuery';

const Home = () => {


    const {
        mutate: getPlanListCountMutation,
        data: planStatusCountData,
        isSuccess: isSuccessPlanStatusCount,
    } = useGetPlanListCount();

    const { t }: any = useOutletContext();
    const { patientList }: any = useOutletContext();
    const { lazyParamsForPatients }: any = useOutletContext();
    const { setLazyParamsForPatients }: any = useOutletContext();
    const { appointmentList }: any = useOutletContext();
    const { appointmentListWithAllData }: any = useOutletContext();

    const [upcomingAppointments, setUpcomingAppointments] = useState<any>()
    const [appointmentsStatusStats, setAppointmentsStatusStats] = useState<any>()

    const [carePlanStatisticsChartData, setCarePlanStatisticsChartData] = useState<any>()

    useEffect(() => {
        if (isSuccessPlanStatusCount) {
            setCarePlanStatisticsChartData(planStatusCountData)

        }
    }, [isSuccessPlanStatusCount]);



    useEffect(() => {
        if (appointmentList) {
            const today = new Date();
            const appointments = appointmentList.filter((a: any) => a.dateAppointmentStart > today && a.status !== "Completed").slice(0, 3);
            setUpcomingAppointments(appointments)
            getPlanListCountMutation()
        }
    }, [appointmentList]);

    useEffect(() => {
        if (appointmentListWithAllData) {
            const appointmentStats = [0, 0, 0]

            for (const appointment of appointmentListWithAllData) {
                if (appointment.status === "Completed") {
                    appointmentStats[0] = appointmentStats[0] + 1
                }
                else if (appointment.status === "Pending" || appointment.status === "Approved") {
                    if (appointment.dateAppointmentStart > new Date()) {
                        appointmentStats[1] = appointmentStats[1] + 1
                    }
                    else {
                        appointmentStats[2] = appointmentStats[2] + 1
                    }
                }
                else {
                    appointmentStats[2] = appointmentStats[2] + 1
                }
            }
            setAppointmentsStatusStats(appointmentStats)
        }
    }, [appointmentListWithAllData]);

    return (
        <div>
            {<Top4Card
                t={t}
                patientList={patientList}
                appointmentsStatusStats={appointmentsStatusStats}
                carePlanStatisticsChartData={carePlanStatisticsChartData} />}

            <Bottom2Card t={t} upcomingAppointments={upcomingAppointments} />

            <Card style={{ borderRadius: "24px", position: "relative" }} className='mt-3'>
                <PatientTable patientList={patientList}
                    lazyParamsForPatients={lazyParamsForPatients} setLazyParamsForPatients={setLazyParamsForPatients} t={t} />
            </Card>

        </div>
    );
}

export default Home