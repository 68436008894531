import React, { useEffect } from 'react';
import { addLocale, PrimeReactProvider } from 'primereact/api';
import Router from './router';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ToastContextProvider } from './Lib/Contexts/toastContext';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import './i18n';

function App() {

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: 1,
      },
      mutations: {
        retry: 1
      },
    }
  });

  addLocale('tr', {
    accept: 'Kabul Et',
    reject: 'Reddet',
    choose: 'Seç',
    upload: 'Yükle',
    cancel: 'İptal',
    dayNames: ['Pazar', 'Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi'],
    dayNamesShort: ['Paz', 'Pzt', 'Sal', 'Çar', 'Per', 'Cum', 'Cmt'],
    dayNamesMin: ['Pz', 'Pt', 'Sa', 'Ça', 'Pe', 'Cu', 'Ct'],
    monthNames: [
      'Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran',
      'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'
    ],
    monthNamesShort: [
      'Oca', 'Şub', 'Mar', 'Nis', 'May', 'Haz',
      'Tem', 'Ağu', 'Eyl', 'Eki', 'Kas', 'Ara'
    ],
    today: 'Bugün',
    clear: 'Temizle',
    weekHeader: 'Hf',
    firstDayOfWeek: 1,
    dateFormat: 'dd/mm/yy',
    weak: 'Zayıf',
    medium: 'Orta',
    strong: 'Güçlü',
    passwordPrompt: 'Şifrenizi girin',
    emptyMessage: 'Gösterilecek veri yok',
    emptyFilterMessage: 'Eşleşen sonuç bulunamadı',
    filter: 'Filtre',
    equals: 'Eşittir',
    notEquals: 'Eşit Değil',
    startsWith: 'İle Başlayan',
    contains: 'İçeren',
    notContains: 'İçermeyen',
    endsWith: 'İle Biten',
    lt: 'Küçük',
    lte: 'Küçük veya Eşit',
    gt: 'Büyük',
    gte: 'Büyük veya Eşit',
    dateIs: 'Tarih',
    dateIsNot: 'Tarih Değil',
    dateBefore: 'Önceki Tarih',
    dateAfter: 'Sonraki Tarih',
    apply: "Uygula"
  });

  const clientId = process.env.REACT_APP_CLIENT_ID_DEV;

  return (
    <DndProvider backend={HTML5Backend}>
      <QueryClientProvider client={queryClient}>
        <GoogleOAuthProvider clientId={clientId as any}>
          <PrimeReactProvider>
            <ToastContextProvider>
              <Router />
            </ToastContextProvider>
          </PrimeReactProvider>
        </GoogleOAuthProvider>
      </QueryClientProvider>
    </DndProvider>

  );
}

export default App;
