import { Button } from "primereact/button"

const ExerciseList = ({ planList, planCount, selectedExercise, setSelectedExercise }: any) => {

    const exerciseList = () => {
        return planList[planCount]?.exerciseList.map((exercise: any) => {
            return (
                <div className="flex px-4 mb-2 justify-content-between gap-2"
                    onClick={() => setSelectedExercise(exercise)}
                    style={{
                        backgroundColor: selectedExercise?.exerciseId === exercise?.exerciseId ? "#2D3967" : "#FFFFFF",
                        borderRadius: "24px",
                        boxShadow: "0 4px 4px -1px rgba(45, 57, 103, 0.05), 0 4px 10px -1px rgba(45, 57, 103, 0.1)",
                        position: "relative",
                        cursor: "pointer",
                        transition: "background-color 0.5s ease-in-out",
                    }}
                >
                    <div className="flex flex-column justify-content-between gap-2 pt-4 pb-4">
                        <Button
                            disabled={planCount === 0}
                            icon={selectedExercise?.exerciseId === exercise?.exerciseId ? "pi pi-check" : undefined}
                            style={{
                                borderRadius: "15px",
                                width: "1.5rem",
                                height: "1.5rem",
                                padding: "0",
                                background: selectedExercise?.exerciseId === exercise?.exerciseId ? "#23CF95" : "white",
                                border: "1px solid #2D3967",
                                color: "#2D3967"
                            }} />
                        <p className="text-lg font-bold mb-0" style={selectedExercise?.exerciseId === exercise?.exerciseId ? { color: "white" } : { color: "#2D3967" }}>{exercise?.exerciseName}</p>
                    </div>

                    <img
                        src={exercise?.exerciseImg}
                        style={{ objectFit: "contain", width: "30%", zIndex: 2 }} />

                    <img
                        src={selectedExercise?.exerciseId === exercise?.exerciseId ? "/exerciseCircleIconOnHover.png" : "/exerciseCircleIcon.png"}
                        style={{
                            objectFit: "contain",
                            width: "35%",
                            position: "absolute",
                            top: "50%",
                            left: "80%",
                            transform: "translate(-50%, -50%)",
                            zIndex: 1
                        }}
                    />
                </div>
            )
        })
    }


    return (
        <>
            {exerciseList()}
        </>)
}

export default ExerciseList