import { Button } from "primereact/button";

const ExerciseItemTemplate = ({selectedExercise,selectedExercises, exercise, handleClick}:any) => {
    const includeInSelectedExercises = selectedExercises.some((selected:any) => selected.exercise.id === exercise.exercise.id)
    const isSelected = includeInSelectedExercises || selectedExercise?.exercise.id === exercise.exercise.id;
    const isAdded = includeInSelectedExercises;
    const circleImageSrc = isSelected ? "/exerciseCircleIconOnHover.png" : "/exerciseCircleIcon.png";

    return (
        <div
        key={exercise?.exercise?.id}
        style={{ width: "18rem" }}
        >
            <div
                className={`flex m-1 h-8rem pl-2 justify-content-between gap-2 ${isSelected && "clickable-data-div"} ${!isAdded && "cursor-pointer"}`}
                style={{
                backgroundColor: "#F5F5F5",
                borderRadius: "18px",
                position: "relative"
                }}
                onClick={() => { handleClick(exercise) }}
            >
                <div className="flex flex-column justify-content-between pt-3 pb-3">
                    <div className="">
                        <Button icon={`${isSelected && "pi pi-check"}`} outlined style={{ backgroundColor: `${isSelected ? "#23CF95" : "inherit"}`, border: `1.5px solid ${isSelected ? "#23CF95" : "#727A99"}`, color: "#2D3967", borderRadius: "32px", width: "1.65rem", height: "1.5rem" }} />
                    </div>
                    <p className="text-sm font-bold" style={{ color: "#2D3967" }}>{exercise.exercise.name}</p>
                </div>

                <img src={exercise.exercise.image} style={{ objectFit: "contain", width: "40%", zIndex: 2 }} />

                <img
                    src={circleImageSrc}
                    style={{
                        objectFit: "contain",
                        width: "40%",
                        position: "absolute",
                        top: "50%",
                        left: "80%",
                        transform: "translate(-50%, -50%)",
                        zIndex: 1
                    }}
                />
            </div>
        </div>
    );
}

export default ExerciseItemTemplate;