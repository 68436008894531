import React from 'react';
import { Card } from 'primereact/card';
import { Slider } from 'primereact/slider';
import CarePlanStatisticsChart from './carePlanStatisticsChart';
import AppointmentsStatisticsChart from './appointmentsStatisticsChart';

const Top4Card = ({ patientList, appointmentsStatusStats, t,carePlanStatisticsChartData }: any) => {
    return (
        <div className="grid homeCards">
            <div className='col'>
                <Card className="h-15rem" style={{ borderRadius: "24px", position: "relative" }}>
                    <div className="flex flex-column justify-content-center gap-3 p-4" >
                        <div className="flex justify-content-between">
                            <img src="/totalPatientsCardIcon.svg" alt="Patients Icon" />

                            <div className="flex flex-column align-items-end">
                                <p className="m-0" style={{ color: "#727A99", whiteSpace: "nowrap" }}>
                                    {t("patientsTotal")}
                                </p>

                                <p className="font-bold text-4xl m-0" style={{ color: "#2D3967" }}>
                                    {patientList ? patientList.length : ""}
                                </p>
                            </div>
                        </div>

                        <div className="flex flex-column">
                            <Slider className="w-full" value={7} max={10} min={0} />
                            <p style={{ fontSize: "clamp(0.7rem, 2vw, 0.9rem)", color: "#727A99" }}>{t("top4CardDescription")}</p>
                        </div>
                    </div>

                    <div className="w-full pr-4 pl-4 absolute flex align-items-center justify-content-between gap-4" style={{
                        backgroundColor: "#EBEDF2",
                        borderTopLeftRadius: "0",
                        borderTopRightRadius: "0",
                        borderBottomLeftRadius: "24px",
                        borderBottomRightRadius: "24px",
                        bottom: 0,
                        left: 0,
                        right: 0,
                        height: "2.5rem"
                    }}>
                        <p style={{ whiteSpace: "nowrap", color: "#727A99",overflow: 'hidden', textOverflow: 'ellipsis' }}><span className='font-bold' style={{ color: "#2D3967" }}>% 5.5</span>  {t("increase")}</p>
                        <p style={{ whiteSpace: "nowrap", color: "#727A99",overflow: 'hidden', textOverflow: 'ellipsis' }}><span className='font-bold' style={{ color: "#2D3967" }}>% 16</span> {t("today")}</p>
                    </div>
                </Card>
            </div>
            <div className='col'>
                <Card className="h-15rem" style={{ borderRadius: "24px", position: "relative" }}>
                    <div className="flex flex-column justify-content-center gap-3 p-4">
                        <div className="flex justify-content-between">
                            <img src="/carePlaneCardIcon.svg" alt="Patients Icon" />

                            <div className="flex flex-column align-items-end">
                                <p className="m-0" style={{ color: "#727A99", whiteSpace: "nowrap" }}>
                                    {t("carePlans")}
                                </p>

                                <p className="font-bold text-4xl m-0" style={{ color: "#2D3967" }}>189</p>
                            </div>
                        </div>

                        <div className="flex flex-column">
                            <Slider className="w-full" value={5} max={10} min={0} />
                            <p style={{ fontSize: "clamp(0.7rem, 2vw, 0.9rem)", color: "#727A99" }}>{t("top4CardDescription")}</p>
                        </div>
                    </div>

                    <div className="w-full pr-4 pl-4 absolute flex align-items-center justify-content-between gap-4" style={{
                        backgroundColor: "#EBEDF2",
                        borderTopLeftRadius: "0",
                        borderTopRightRadius: "0",
                        borderBottomLeftRadius: "24px",
                        borderBottomRightRadius: "24px",
                        bottom: 0,
                        left: 0,
                        right: 0,
                        height: "2.5rem"
                    }}>
                        <p style={{ whiteSpace: "nowrap", color: "#727A99",overflow: 'hidden', textOverflow: 'ellipsis' }}><span className='font-bold' style={{ color: "#2D3967" }}>% 59</span>  {t("increase")}</p>
                        <p style={{ whiteSpace: "nowrap", color: "#727A99",overflow: 'hidden', textOverflow: 'ellipsis' }}><span className='font-bold' style={{ color: "#2D3967" }}>% 17</span> {t("today")}</p>
                    </div>
                </Card>
            </div>
            <div className='col'>
                <Card className="h-15rem" style={{ borderRadius: "24px", position: "relative" }}>
                    <CarePlanStatisticsChart t={t} carePlanStatisticsChartData={carePlanStatisticsChartData}/>
                </Card>
            </div>
            <div className='col'>
                <Card className="h-15rem" style={{ borderRadius: "24px", position: "relative" }}>
                    <AppointmentsStatisticsChart t={t} appointmentsStatusStats={appointmentsStatusStats} />
                </Card>

            </div>
        </div>
    )
}

export default Top4Card